$(function() {
    if($('.slide-banner').length) {
        $('.slide-banner').owlCarousel({
            loop:true,
            items:1,
            autoplay: true,
            mouseDrag: false,
        })
    }

    if($('.owl-inline-slide').length){
        $('.owl-inline-slide').owlCarousel({
            margin:30,
            loop: false,
            mouseDrag: false,
            responsive: {
                0:{
                    items:1
                },
                768: {
                    items: 2,
                },
            }
        });
    }

    if($('.owl-product').length){
        $('.owl-product').owlCarousel({
            margin:30,
            loop: false,
            mouseDrag: false,
            responsive: {
                0:{
                    items:1
                },
                768: {
                    items: 3,
                },
            }
        });
    }

    if($('.owl-portfolio').length){
        $('.owl-portfolio').owlCarousel({
            loop: true,
            autoplay: true,
            mouseDrag: true,
            items:1,
        });
    }
});
