$(function() {

    var path = window.location.pathname;
    
    var href = decodeURI(path.slice(3));

    var level1 = "/"+path.split('/').filter((v)=>v!='').splice(1)[0];
   
    if(href == '' || href == '/home') {
        $(`.nav-link[href="/"]`).parents('.nav-item').addClass('active');
    }

    if($(`.dropdown-item[href="${href}"]`).length) {
        $(`.dropdown-item[href="${href}"]`).addClass('active');
        $(`.dropdown-item[href="${href}"]`).parents('.nav-item').addClass('active');
    }

    if($(`.nav-link[href="${href}"]`).length) {
        $(`.nav-link[href="${href}"]`).parents('.nav-item').addClass('active');        
    } 
    
    if($(`.nav-link[href="${level1}"]`.length)) {
        $(`.nav-link[href="${level1}"]`).parents('.nav-item').addClass('active');
    }
    
})